<template>
    <VueRecaptcha class="reCAPTCHA" :sitekey="sitekey" :loadRecaptchaScript="true" @verify="validate"/>
</template>
<script>    
    import VueRecaptcha from 'vue-recaptcha';

    export default {
        
        components: {
            VueRecaptcha
        },
        data () {
            return {
                sitekey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY
            }
        },
        methods: {
            validate (response) {
                this.$emit("input", response);
            }
        }
    }
</script>

<style scoped>
    .reCAPTCHA {
        text-align: center;
    }
</style>
<template>
    <v-container fluid>
        <v-layout align-center justify-center style="padding-top: 5%">
            <v-col 
                cols="12"
                lg="4"
                md="12"
                sm="12"
            >
                <v-form @submit.prevent="logIn('form-login')" data-vv-scope="form-login" autocomplete="off">
                    <v-row>
                        <v-col cols="12" style="text-align: center;">
                            <img src="@/assets/images/logo.png" style="height: 100px;" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" style="padding-top: 50px;"></v-col>
                    </v-row>
                    <v-row style="height: 100px;">
                        <v-col 
                            cols="12"
                        >
                            <label>{{ $t('email') }}</label>
                            <v-text-field
                                v-model="email"
                                :rules=[validations.required,validations.email]
                                single-line
                                outlined
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                        >
                            <label>{{ $t('senha') }}</label>
                            <v-text-field
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                autocomplete="new-password"
                                :type="showPassword ? 'text' : 'password'"
                                v-model="password"
                                single-line
                                outlined
                                :rules=[validations.required]
                                @click:append="showPassword = !showPassword"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" style="text-align: center;">
                            <v-btn block dark type="submit" :disabled="!disableButton" :loading="loading">{{ $t('entrar') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-layout>
        <v-layout align-center justify-center style="margin-top: 30px;">
            <v-chip
                class="ma-2"
                label
                link
                outlined
                style="border: none !important;"
                @click="showRecoveryPassword = true"
            >
                {{ $t('esqueceuSenha') }}
            </v-chip>
        </v-layout>
        <div class="text-center">
            <v-dialog
                v-model="showRecoveryPassword"
                transition="dialog-top-transition"
                width="500"
                :fullscreen="$vuetify.breakpoint.mobile"
            >
            
                <v-card>
                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                        {{ $t('recuperacaoSenha') }}
                    </v-card-title>

                    <v-card-text style="margin-top: 20px;">
                        <v-row style="height: 120px;">
                            <v-col cols="12">
                                <label>{{ $t('seuEmail') }}</label>
                                <v-text-field
                                    v-model="emailRecovery"
                                    :rules=[validations.required,validations.email]
                                    single-line
                                    outlined
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row style="height: 120px;">
                            <v-col cols="12">
                                <Recaptcha v-model="responseRecaptcha" />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <div style="width: 100%; text-align: center;">
                            <v-btn
                                class="mx-2"
                                outlined
                                @click="recoverPassword()"
                            >
                                {{ $t('recuperar') }}
                            </v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <DialogMessage 
                v-on:methodOKToCall="dialogMessage.methodOK"
                :showDialogMessage.sync="dialogMessage.show"
                :headerTitle="dialogMessage.headerText"
                :bodyText="dialogMessage.bodyText"
            />
        </div>
    </v-container>
</template>

<script>

    import { required, validEmail } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import router from '@/router';
    import Recaptcha from '@/components/Layout/Recaptcha'
    import DialogMessage from "@/components/Layout/DialogMessage";

    export default {

        mixins: [Helpers],

        components: {
            Recaptcha,
            DialogMessage
        },

        data: () => ({

            loading: false,
            email: "",
            emailRecovery: "",
            password: "",
            showPassword: false,
            showRecoveryPassword: false,
            validateRecaptcha: false,
            responseRecaptcha: "",

            validations: {
                email: validEmail,
                required: required,
            },

            dialogMessage: {
                show: false,
                headerText: "",
                bodyText: "",
                methodOK: ( () => {} ),
            },
        }),

        computed: {

            disableButton() {
                return this.email !== "" && this.password !== "";
            }
        },

        watch: {
            async "showRecoveryPassword"() {
                let reCAPTCHA = await document.getElementsByClassName('reCAPTCHA');
                
                if (reCAPTCHA) {
                    reCAPTCHA[0].childNodes[0].removeAttribute('style');
                }
            }
        },

        created() {
            localStorage.clear();
            this.$store.dispatch("empresaModule/ListDistribuidor");
            this.$store.dispatch("empresaModule/ListClientes");
            this.$store.dispatch("empresaModule/ListKeyAccount");
            this.$store.dispatch("naturezaModule/List");
        },

        methods: {

            async setRoutes(userLogged) {

                let listRoutes = [];

                userLogged.listGrupoMenu.map(async itemGrupoMenu => {

                    itemGrupoMenu.listMenu.forEach(itemMenu => {

                        var routerExists = router.getRoutes().filter(rte => rte.name === itemMenu.routeName);

                        if (routerExists === null || routerExists === undefined || routerExists.length <= 0) {

                            router.addRoute({
                                path: itemMenu.path,
                                name: itemMenu.routeName,
                                props: itemMenu.props,
                                component: () => import( /*webpackChunkName: "application" */ `@/views/${itemMenu.urlFile}`),
                                meta: { requiresAuth: itemMenu.requiredAuthentication }
                            })
                        }
                        
                        listRoutes.push({
                            path: itemMenu.path,
                            name: itemMenu.routeName,
                            props: itemMenu.props,
                            urlFile: itemMenu.urlFile,
                            requiresAuth: itemMenu.requiredAuthentication
                        })

                        localStorage.setItem('listRouters', JSON.stringify(listRoutes));

                    });
                });

                this.addCarrinhoRouter(listRoutes);
            },

            async addCarrinhoRouter(listRoutes) {
                
                router.addRoute({
                    path: "/vendas/carrinho",
                    name: "carrinho",
                    props: false,
                    component: () => import( /*webpackChunkName: "application" */ `@/views/Vendas/Carrinho`),
                    meta: { requiresAuth: true }
                });

                listRoutes.push({
                    path: "/vendas/carrinho",
                    name: "carrinho",
                    props: false,
                    urlFile: "Vendas/Carrinho",
                    requiresAuth: true
                });
                
                localStorage.setItem('listRouters', JSON.stringify(listRoutes));
            },

            async setTheme(userLogged) {
                if (userLogged.corTema === 1)  { 
                    //dark
                    this.$vuetify.theme.dark = true;
                }
                else { 
                    // light
                    this.$vuetify.theme.dark = false;
                }
            },

            async recoverPassword() {

                if (this.responseRecaptcha == null || this.responseRecaptcha == undefined || this.responseRecaptcha == "") {
                    this.showToast("error", this.$t('aviso'), this.$t('recoverPasswordMessage1'));
                }
                else {

                    if (this.emailRecovery == null || this.emailRecovery == undefined || this.emailRecovery == "") {
                        this.showToast("error", this.$t('aviso'), this.$t('recoverPasswordMessage2'));
                    }
                    else {
                        let requestRecovery = {
                            email: this.emailRecovery,
                            recaptchaResponse: this.responseRecaptcha
                        }

                        const recoveryResult = await this.$store.dispatch("logInModule/RecoveryPassword", requestRecovery);

                        if (recoveryResult.success === true) {
                            
                            const message = this.$t('recoverPasswordMessage3');

                            this.dialogMessage = {
                                show: true,
                                headerText: this.$t('sucesso'),
                                bodyText: message,
                                methodOK: (() => { window.location.reload(); }),
                            };
                        }
                        else {
                            this.showToast("error", this.$t('aviso'), recoveryResult.message);
                        }
                    }
                }
            },

            async logIn() {
                this.loading = true;
                this.showLoading();

                if (this.email.toLowerCase().includes("integracao")) {
                    this.showToast("error", this.$t('aviso'), this.$t('logInCredencialInvalida'));
                }
                else {

                    let requestLogin = {
                        email: this.email,
                        password: this.password
                    }

                    const loginResult = await this.$store.dispatch("logInModule/Auth", requestLogin);

                    if (loginResult.success === true) {
                        this.showToast("success", this.$t('sucesso'), loginResult.message);
                        await this.setTheme(loginResult.data);
                        await this.setRoutes(loginResult.data);
                        this.$router.push("/");
                    }
                    else {
                        this.showToast("error", this.$t('aviso'), loginResult.message);
                    }
                }

                this.loading = false;
                this.hideLoading();
            }
        }
    };
</script>